import React, { useState } from 'react';
import { format } from 'date-fns';
import {
  ColumnSort,
  DataTable,
  DataTableColumn,
  TextButton,
} from '@allergan-data-labs/alle-elements';
import { Box, Flex, Text } from '@chakra-ui/react';
import { OfferStatusBadge } from '@allergan-data-labs/admin-business-component-library/src/components/offerBuilder/offerStatusBadge/offerStatusBadge';
import { formatNumberWithCommas } from '@allergan-data-labs/universal-component-library/src/helpers/format';
import { ChevronRightIcon } from '@allergan-data-labs/alle-elements-icons/20/chevronRightIcon';
import {
  Offer,
  OfferStatus,
  OfferValueTypeLabelMap,
  OfferActivationLabelMap,
} from '@allergan-data-labs/admin-business-component-library/src/components/offerBuilder/types';
import { BaseComponentProps } from '../../common';
import { getSortedItems } from '../../../utils';
import { ContextMenuCell, MenuActionType } from '../cells';

export interface OfferBuilderDirectToConsumerTableProps
  extends BaseComponentProps {
  data: Array<Offer>;
  isLoading?: boolean;
  onInternalNameClick: (internalName: string) => void;
  onActionItemClick: (offer: Offer, actionType: MenuActionType) => void;
}

const OfferBuilderDirectToConsumerTable: React.FC<
  OfferBuilderDirectToConsumerTableProps
> = ({
  data,
  dataTestId = 'direct-to-consume-table',
  isLoading = false,
  onInternalNameClick,
  onActionItemClick,
}) => {
  const columns: Array<DataTableColumn<Offer>> = [
    {
      column: 'Internal Name',
      field: 'name',
      renderCell: (_, name) => {
        return (
          <Flex flexDirection="column" minWidth="530px">
            <TextButton
              variant="ghost"
              colorScheme="brand"
              onClick={() => onInternalNameClick(_.id)}
              rightIcon={<ChevronRightIcon />}
              justifyContent="flex-start"
            >
              <Text textStyle="Body/Medium/Regular" as="span" isTruncated>
                {name as string}
              </Text>
            </TextButton>
          </Flex>
        );
      },
    },
    {
      column: 'Status',
      field: 'status',
      renderCell: (_, status) => {
        return (
          <Box minWidth="129px">
            <OfferStatusBadge
              status={status as OfferStatus}
              pendingChangeRequests={false}
            />
          </Box>
        );
      },
    },
    {
      column: 'Start Date',
      field: 'startDate',
      renderCell: (offer) => {
        const dateFormatted =
          format(offer.startDate, 'MM.dd.yy hh:mm a') || 'n/d';

        return (
          <Box minWidth="200px">
            <Text textStyle="Body/Medium/Regular" as="span">
              {dateFormatted}
            </Text>
          </Box>
        );
      },
    },
    {
      column: 'Type',
      field: 'valueType',
      renderCell: (offer) => {
        return (
          <Box textTransform="capitalize">
            {offer.valueType ? OfferValueTypeLabelMap[offer.valueType] : 'n/d'}
          </Box>
        );
      },
    },
    {
      column: 'Activation',
      field: 'activation',
      renderCell: (offer) => {
        return (
          <Text textStyle="Body/Medium/Regular" as="span">
            <>
              {offer.activation
                ? OfferActivationLabelMap[offer.activation]
                : 'n/d'}
            </>
          </Text>
        );
      },
    },
    {
      column: 'Claimed',
      field: 'claimed',
      renderCell: (_, claimed) => {
        return (
          <Text
            textStyle="Body/Medium/Regular"
            as="span"
            align="right"
            width="100%"
            paddingRight="40px"
          >
            {claimed as string}
          </Text>
        );
      },
    },
    {
      column: 'Redeemed',
      field: 'redeemed',
      renderCell: (_, redeemed) => {
        return (
          <Text
            textStyle="Body/Medium/Regular"
            as="span"
            align="right"
            width="100%"
            paddingRight="40px"
          >
            {redeemed ? formatNumberWithCommas(redeemed as string) : 'n/d'}
          </Text>
        );
      },
    },
    {
      column: 'Remaining',
      field: 'remaining',
      renderCell: (_, remaining) => {
        return (
          <>
            <Text
              textStyle="Body/Medium/Regular"
              as="span"
              align="right"
              width="100%"
              paddingRight="40px"
            >
              {remaining ? formatNumberWithCommas(remaining as string) : 'n/d'}
            </Text>
          </>
        );
      },
    },
    {
      column: '',
      field: 'menu',
      renderCell: (offer) => {
        return (
          <>
            <ContextMenuCell
              flexGrow={1}
              offer={offer as any}
              onActionItemClick={onActionItemClick}
              data-testid={`user-${(offer as any).id}-context-menu`}
            />
          </>
        );
      },
    },
  ];

  const [sortConfig, setSortConfig] = useState<ColumnSort>({
    id: null,
    direction: 'asc',
  });

  const handleSort = (sortingState: ColumnSort) => {
    setSortConfig(sortingState);
  };

  return (
    <Box
      sx={{
        'th:first-child button': {
          display: 'none',
        },
        'th:last-child div': {
          display: 'none',
        },
      }}
    >
      <DataTable
        isLoading={isLoading}
        data-testid={dataTestId}
        columns={columns}
        rowGap={8}
        data={getSortedItems(sortConfig, data)}
        onSort={handleSort}
        variant="closed"
        sx={{
          '& tbody tr': { cursor: 'initial !important' },
        }}
      />
    </Box>
  );
};

export { OfferBuilderDirectToConsumerTable };
