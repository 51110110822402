import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { OptimizelyUser } from '@allergan-data-labs/component-library/src/optimizely/alleFeatureProvider';
import { optimizelyUuidKey } from '../application.config';

export function useResolveOptimizelyUser() {
  const [user, setUser] = useState<OptimizelyUser<{}>>({
    id: '',
    attributes: {},
  });
  useEffect(() => {
    let optimizelyUuid = '';

    if (!localStorage.getItem(optimizelyUuidKey)) {
      optimizelyUuid = uuidv4();
      localStorage.setItem(optimizelyUuidKey, optimizelyUuid);
    } else {
      optimizelyUuid = localStorage.getItem(optimizelyUuidKey) as string;
    }

    const defaultUser: OptimizelyUser = {
      id: optimizelyUuid,
      attributes: {},
    };
    setUser(defaultUser);
  }, []);

  return user;
}
