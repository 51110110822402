export enum Routes {
  home = '/',
  login = '/login',
  logout = '/logout',

  // PATIENT
  patientSearch = '/patient',
  patient = '/patient/:patientName/:patientId',
  patientOffers = '/patient/:patientName/:patientId/offers',
  patientOfferDetails = '/patient/:patientName/:patientId/offers/:offerId',
  appreciationOffer = '/patient/:patientName/:patientId/appreciationOffer',
  patientGiftCards = '/patient/:patientName/:patientId/gift-cards',
  patientGiftCardDetails = '/patient/:patientName/:patientId/gift-cards/:cardId',
  communicationPreferences = '/patient/:patientName/:patientId/communication',
  patientEditTransaction = '/patient/:patientName/:patientId/tx/:transactionId',

  a4aConsumerOffers = '/a4a/consumer/:patientName/:patientId/offers',
  a4aConsumerOfferDetails = '/a4a/consumer/:patientName/:patientId/offers/:offerId',
  a4aConsumerAppreciationOffer = '/a4a/consumer/:patientName/:patientId/appreciationOffer',
  a4aConsumerGiftCards = '/a4a/consumer/:patientName/:patientId/gift-cards',
  a4aConsumerGiftCardDetails = '/a4a/consumer/:patientName/:patientId/gift-cards/:cardId',
  a4aConsumerCommunicationPreferences = '/a4a/consumer/:patientName/:patientId/communication',

  // PRACTICE
  practiceSearch = '/practice',
  practice = '/practice/:practiceName/:practiceId',
  debugPlatformComponentLibrary = '/debug/platform-component-library',
  practiceReimbursements = '/practice/:practiceName/:practiceId/reimbursements',
  practiceReimbursementStatement = '/practice/:practiceName/:practiceId/reimbursements/:payoutId',
  practiceOffers = '/practice/:practiceName/:practiceId/offers',
  practiceOfferDetails = '/practice/:practiceName/:practiceId/offers/:offerId',
  practiceWallet = '/practice/:practiceName/:practiceId/wallet',
  practiceReceipt = '/practice/:practiceName/:practiceId/receipt/:transactionId',
  practiceEditTransaction = '/practice/:practiceName/:practiceId/tx/:transactionId',

  a4aPracticeOfferDetails = '/a4a/practice/:practiceName/:practiceId/offers/:offerId',

  // PROVIDER
  providerUserSearch = '/providerUser',
  providerUser = '/providerUser/:providerUserId',

  // BUSINESS
  business = '/business/:providerOrgId',
  user = '/user/:userId',

  // OFFER BUILDER
  offerBuilder = '/offer-builder',
  offerBuilderDashboard = '/offer-builder/dashboard',
  offerBuilderNew = '/offer-builder/new',
  a4aOfferBuilderNew = '/a4a/offer-builder/new',
  offerBuilderEdit = '/offer-builder/:offerId',
  offerBuilderChangeRequests = '/offer-builder/:offerId/change-requests',
  offerBuilderEditName = '/offer-builder/:offerId/name',
  offerBuilderEditDistributionType = '/offer-builder/:offerId/distribution-type',
  offerBuilderEditOfferType = '/offer-builder/:offerId/offer-type',
  offerBuilderEditActivationType = '/offer-builder/:offerId/activation-type',
  offerBuilderEditEarnedPointsDetails = '/offer-builder/:offerId/earned-points-details',
  offerBuilderEditValueAndAllocation = '/offer-builder/:offerId/value-and-allocation',
  offerBuilderEditDollarsOffRules = '/offer-builder/:offerId/dollars-off-rules',
  offerBuilderEditDuration = '/offer-builder/:offerId/duration',
  offerBuilderEditExpiration = '/offer-builder/:offerId/expiration',
  offerBuilderEditBrandSelection = '/offer-builder/:offerId/brand-selection',
  offerBuilderEditEarnedPointsBrandSelection = '/offer-builder/:offerId/earned-points-brand-selection',
  offerBuilderEditEligibleAudiences = '/offer-builder/:offerId/eligibility',
  offerBuilderEditAudienceDelivery = '/offer-builder/:offerId/delivery',
  offerBuilderEditTerms = '/offer-builder/:offerId/terms',
  offerBuilderEditWalletDetails = '/offer-builder/:offerId/wallet-details',
  offerBuilderReviewOffer = '/offer-builder/:offerId/review',
  offerBuilderPreviewWallet = '/offer-builder-preview/:offerId/wallet',
  a4aOfferBuilderPreviewWallet = '/a4a/offer-builder-preview/:offerId/wallet',
  offerBuilderPreviewDetails = '/offer-builder-preview/:offerId/details',
  offerBuilderDesignGiftCard = '/offer-builder/:offerId/design-gift-card',
  offerBuilderGiftCardRules = '/offer-builder/:offerId/gift-card-rules',
  offerBuilderApprovalStatus = '/offer-builder/:offerId/approval-status',

  // RDF
  offerBuilderRDFOfferDetails = '/offer-builder/:offerId/rdf/offer-details',
  offerBuilderRDFBrandSelection = '/offer-builder/:offerId/rdf/brand-selection',
  offerBuilderRDFWalletDetails = '/offer-builder/:offerId/rdf/wallet-details',
  offerBuilderRDFDuration = '/offer-builder/:offerId/rdf/duration',
  offerBuilderRDFExpiration = '/offer-builder/:offerId/rdf/expiration',
  offerBuilderRDFReview = '/offer-builder/:offerId/rdf/review',

  // POINTS and EARNINGS
  pointsAndEarning = '/points-and-earning',

  // GIFT CARD
  giftCardSearch = '/gift-card',
  giftCardDetails = '/gift-card/:cardId',
  a4aGiftCardDetailsNew = '/a4a/gift-card/:cardId',
  globalIsi = '/global-isi',
  paymentsAdmin = '/payments-admin',

  // A4A
  a4a = '/a4a',
  a4aBusiness = '/a4a/business',
  a4aBusinessSearch = '/a4a/business-search',
  a4aBusinessDetails = '/a4a/business/:providerOrgId',
  a4aBusinessUsers = '/a4a/business/:businessId/users',
  a4aBusinessUser = '/a4a/business-user/:userId',
  a4aBusinessUserDetails = '/a4a/business/:userId',
  a4aBusinessLocations = '/a4a/business/:businessId/locations',
  a4aLocationDetails = '/a4a/business/:businessId/location/:locationId/details',
  a4aLocationUsers = '/a4a/business/:businessId/location/:locationId/users',
  a4aConsumerSearch = '/a4a/consumers',
  a4aConsumerDetails = '/a4a/consumers/:patientName/:patientId',
  a4aOfferBuilder = '/a4a/offer-builder',
  a4aOfferBuilderPreviewDetails = '/a4a/offer-builder-preview/:offerId/details',
  a4aGiftCardSearch = '/a4a/gift-card',
  a4aGiftCardDetails = '/a4a/gift-card/:cardId',
  a4aPaymentsAdmin = '/a4a/payments-admin',
}
