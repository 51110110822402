import { MenuActionType } from '@allergan-data-labs/admin-business-component-library/src/components/offerBuilder/cells';

export interface Offer {
  id: string;
  name: string;
  status: OfferStatus;
  value: string;
  startDate: Date;
  type: OfferType;
  valueType: OfferValueType;
  activation: OfferActivation;
  scanned?: string;
  claimed: string;
  redeemed: string;
  remaining: string;
  allocated: string;

  /* To validate if will exist or not */
  universalMarketingId?: string;

  /* For the table column customization */
  menu?: string;
}

export enum OfferStatus {
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Live = 'LIVE',
  OnHold = 'ON_HOLD',
  PendingApproval = 'PENDING_APPROVAL',
  Declined = 'DECLINED',
  Scheduled = 'SCHEDULED',
  Approved = 'APPROVED',
  DistributionScheduled = 'DISTRIBUTION_SCHEDULED',
  DistributionError = 'DISTRIBUTION_ERROR',
}

export enum OfferType {
  DirectToConsumer = 'DIRECT_TO_CONSUMER',
  PracticeToConsumer = 'PRACTICE_TO_CONSUMER',
  RepDispensedFlash = 'REP_DISPENSED_FLASH',
}

export enum OfferValueType {
  DollarsOff = 'DOLLARS_OFF',
  Points = 'POINTS',
  GiftCard = 'GIFT_CARD',
}

export enum OfferActivation {
  Earned = 'EARNED',
  Given = 'GIVEN',
  OptIn = 'OPT_IN',
  Purchased = 'PURCHASED',
  Flash = 'FLASH',
}

export interface TabsData {
  directToConsumerCount?: number | null;
  repDispensedFlashCount?: number | null;
  practiceToConsumerCount?: number | null;
  directToConsumerData: Offer[];
  repDispensedFlashData: Offer[];
  practiceToConsumerData: Offer[];
}

export interface OffersDataMeta {
  totalSize: number;
  totalPage: number;

  // REMOVE
  itemCount: number;
  itemsPerPage: number;
  currentPage: number;
}

export interface OfferBuilderTabPanelProps {
  offers: OffersData | undefined;
  isLoading: boolean;
  currentPage: number;
  pageSize: number;
  onPaginationSizeChange: (pageSize: number) => void;
  onPaginationChange: (pageSize: number) => void;
  onActionItemClick: (offer: Offer, actionType: MenuActionType) => void;
  searchTerms?: string;
}

export enum SortType {
  ASC,
  DESC,
}

export interface OffersFilter {
  page: number;
  limit: number;
  sortDir?: SortType;
  sortCol?: string;
  searchTerm?: string;
  type: OfferType;
}

export interface OffersData {
  data: Offer[];
  pagination: OffersDataMeta;
}

export const OfferStatusLabelMap = {
  [OfferStatus.Draft]: 'Draft',
  [OfferStatus.Live]: 'Live',
  [OfferStatus.Expired]: 'Expired',
  [OfferStatus.OnHold]: 'On Hold',
  [OfferStatus.PendingApproval]: 'Pending Approval',
  [OfferStatus.Declined]: 'Declined',
  [OfferStatus.Approved]: 'Approved',
  [OfferStatus.Scheduled]: 'Scheduled',
  [OfferStatus.DistributionScheduled]: 'Distribution Scheduled',
  [OfferStatus.DistributionError]: 'Distribution Failed',
};

export const OfferActivationLabelMap = {
  [OfferActivation.Earned]: 'Earned',
  [OfferActivation.Given]: 'Given',
  [OfferActivation.OptIn]: 'Opt In',
  [OfferActivation.Purchased]: 'Purchased',
  [OfferActivation.Flash]: 'Flash',
};

export const OfferValueTypeLabelMap = {
  [OfferValueType.DollarsOff]: 'Dollars Off',
  [OfferValueType.GiftCard]: 'Gift Card',
  [OfferValueType.Points]: 'Points',
};
